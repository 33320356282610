import Home from "../pages/Home";
import Cart from "../components/Cart/Cart";
import Product from "../components/Product/Product";
import ProductDetails from "../components/Product/ProductDetails";
import Checkout from "../pages/Checkout";
import Order from "../components/Order/Order";
import OrderDetails from "../pages/OrderDetails";
import LoginForm from "../Auth/LoginForm";
import RegisterForm from "../Auth/RegisterForm";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/cart", element: <Cart /> },
  { path: "/login", element: <LoginForm /> },
  { path: "/register", element: <RegisterForm /> },
  { path: "/product/:productId", element: <ProductDetails /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/account/orders", element: <Order /> },
  // { path: "/admin", element: <AdminPage /> },
  { path: "/account/order/:orderId", element: <OrderDetails /> },
  { path: "/:levelOne/:levelTwo", element: <Product /> },
];
